//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
am4core.useTheme(am4themes_animated);

export default {
  name: 'heartbeat',
  data() {
    return {
      showChart: true,
      showDuration: false,
      showInnerLoading: false,
      isCall: false,
      chatsData: []
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme'
    }),
  },
  methods: {
    ...mapActions({
      requestAISessionsAction: 'requestAISessionsList',
      requestAISessionsPerDay: 'requestAISessionsPerDay',
    }),
    createBarChartSenderRequest() {
      const that = this;
      if (this.heartBeatChart) {
        this.heartBeatChart.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      // Create chart instance
      const chart = am4core.create(this.$refs.heartBeatChart, am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();
      chart.doubleClickDuration = 1200;
      // Add data
      chart.data = this.chatsData;
      chart.scrollbarX = false;

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'start_time';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 110;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.colorField = 'color';
      series.dataFields.valueY = 'senderRequests';
      series.dataFields.categoryX = 'start_time';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 1;
      // series.columns.template.fill = am4core.color("#ff4444");
      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      // chart series bar width
      if (this.chatsData.length < 10 && this.chatsData.length > 8) {
        series.columns.template.width = am4core.percent(50);
      } else if (this.chatsData.length < 8 && this.chatsData.length > 6) {
        series.columns.template.width = am4core.percent(40);
      } else if (this.chatsData.length < 6 && this.chatsData.length > 4) {
        series.columns.template.width = am4core.percent(30);
      } else if (this.chatsData.length < 4 && this.chatsData.length > 2) {
        series.columns.template.width = am4core.percent(20);
      } else if (this.chatsData.length < 2) {
        series.columns.template.width = am4core.percent(10);
      }

      // double click event
      series.columns.template.events.on('hit', (ev) => {
        const ts = new Date().getTime();
        const { index } = ev.target._dataItem;
        if (ts - chart.lastClickTs < chart.doubleClickDuration && chart.lastClickIndex == index) {
          // it's double click!
          // let's clear the timeout so the "click" event does not fire
          if (chart.clickTimeout) {
            clearTimeout(chart.clickTimeout);
          }

          // reset last click
          chart.lastClickTs = 0;
          chart.lastClickIndex = null;

          // check if duration tab is showing(only enabled for time per hour)
          if (that.showDuration == false) {
            // now let's call getSingleDateData on double-click
            // that.getSingleDateData(ev.target._dataItem.dataContext);
            eventBus.updateDateFilter({
              start_date: ev.target._dataItem.dataContext.start_time,
              end_date: ev.target._dataItem.dataContext.start_time,
            });
          }
        } else {
        }
        chart.lastClickTs = ts;
        chart.lastClickIndex = index;
      }, this);

      // on hover, make corner radiuses bigger
      const hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));
      categoryAxis.sortBySeries = categoryAxis.dataFields.category;
      categoryAxis.renderer.inversed = true;
      // disable zoom
      chart.zoomOutButton.disabled = true;

      // disable Scrollbar
      chart.hideXScrollbar = true;
      chart.hideYScrollbar = true;
      // Cursor
      chart.cursor = new am4charts.XYCursor();
      // disable zooming
      chart.cursor.behavior = 'none';
      // disable cursor line
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      // Disable general cursor tootltip for range view
      valueAxis.cursorTooltipEnabled = false;
      that.heartBeatChart = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Heartbeat-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      chart.responsive.enabled = true;
     
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        if (date.start == date.end) {
          // if both date are same then just get data per hour for that date
          that.getSingleDateData({
            start_time: date.start,
            end_date: date.end,
          });
        } else {
          // if both date are not same do a normal api call
          const data = {
            aiAccount: that.currentAIAccount.id,
            startdate: date.start,
            enddate: date.end,
            from: 'onDateUpdate',
          };
          that.updateAISesions(data);
        }
      } else {
        console.log('invalid date');
      }
    },
    updateAISesions(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.isCall = true;
        if (this.heartBeatChart) {
          this.heartBeatChart.dispose();
        }
        this.requestAISessionsAction(data).then((res) => {
          if (res.result.length > 0) {
            that.showChart = true;
            that.showDuration = false;
            setTimeout(() => {
            that.chatsData = res.result
              that.createBarChartSenderRequest();
            });
          } else {
            setTimeout(() => {
              that.showInnerLoading = false;
              that.showChart = false;
              that.showDuration = false;
            }, 100);
          }
        }).catch((err) => {
          that.showInnerLoading = false;
          that.showChart = false;
          that.showDuration = false;
        }).finally(() => {
          setTimeout(() => {
            that.showInnerLoading = false;
            that.isCall = false;
          }, 1000);
        });
      }
    },
    getSingleDateData(obj) {
      const that = this;
      if (!that.isCall) {
        that.isCall = true;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: obj.start_time,
          enddate: obj.end_date,
        };
        if (this.heartBeatChart) {
          this.heartBeatChart.dispose();
        }
        that.showInnerLoading = true;
        that.requestAISessionsPerDay(data).then((res) => {
          if (res.result.length > 0) {
            that.showChart = true;
            that.chatsData = res.result
            setTimeout(() => {
              that.createBarChartSenderRequest();
            });
            that.showDuration = true;
          } else {
            setTimeout(() => {
              that.showChart = false;
            }, 100);
          }
        }).catch((err) => {
          that.showInnerLoading = false;
          that.showChart = false;
          that.showDuration = false;
        }).finally(() => {
          setTimeout(() => {
            that.showInnerLoading = false;
            that.isCall = false;
          }, 1000);
        });
      }
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updateAISesions(data);
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.heartBeatChart) {
          that.heartBeatChart.dispose();
        }
      }
    },
    darkTheme(){
      this.createBarChartSenderRequest()
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    if (data.startdate == data.enddate) {
      that.getSingleDateData({
        start_time: data.startdate,
        end_date: data.enddate,
      });
    } else {
      that.updateAISesions(data);
    }
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
